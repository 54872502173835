/**
 * react-bnb-gallery (https://github.com/peterpalau/react-bnb-gallery)
 * Licensed under MIT (https://github.com/peterpalau/react-bnb-gallery/blob/master/LICENSE)
 */
@keyframes GalleryLoadingSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-spinner {
  margin: 60px auto;
  position: relative;
  border-top: 0.35em solid rgba(255, 255, 255, 0.2);
  border-right: 0.35em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.35em solid rgba(255, 255, 255, 0.2);
  border-left: 0.35em solid #fff;
  border-radius: 100%;
  width: 6em;
  height: 6em;
}
.loading-spinner::after {
  width: 6em;
  height: 6em;
  border-radius: 100%;
}

.loading-spinner,
.gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list
  li
  button.thumbnail-button::after {
  font-size: 10px;
  text-indent: -9999em;
  transform: translateZ(0);
  animation: GalleryLoadingSpinner 1.1s infinite linear;
}

.mode-light .loading-spinner {
  border-top-color: rgba(0, 0, 0, 0.1);
  border-right-color: rgba(0, 0, 0, 0.1);
  border-bottom-color: rgba(0, 0, 0, 0.1);
  border-left-color: #111;
}

.gallery-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
}
.gallery-modal .gallery-modal--overlay {
  background-color: black;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.gallery-modal--preload {
  display: none;
}

.gallery-modal--container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  transform: translate3d(0, 0, 0);
  z-index: 1;
}

.gallery-modal--table {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
}

.gallery-modal--cell {
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle;
}

.gallery-modal--content {
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  height: 100%;
  max-width: 100%;
}

.gallery-content {
  display: table;
  width: 100%;
  height: 100%;
}

.gallery-top {
  display: table-row;
}

.gallery-top--inner {
  padding: 60 15px 20;
}

.gallery {
  display: table-cell;
  vertical-align: middle;
  font-size: 1rem;
  width: 100%;
  padding-bottom: 10;
}
.gallery ul.gallery-images--ul li.gallery-media-photo.loading .loading-spinner,
.gallery
  ul.gallery-images--ul
  li.gallery-media-photo
  .picture.loading
  .loading-spinner {
  position: absolute;
  z-index: 1001;
  top: 50%;
  left: 50%;
  margin: -1.5rem 0 0 -1.5rem;
  /* visually centered */
  transform: translate(-50%, -50%);
}
.gallery .gallery-main {
  position: relative;
  z-index: 9;
}
.gallery .gallery-photos {
  width: 100%;
  max-width: 105vh;
  margin: 0 auto;
}
.gallery .gallery-photos .gallery-empty {
  color: #fff;
  font-size: 1.45rem;
  display: block;
  text-align: center;
  padding: 25% 0;
}
.gallery .gallery-photo {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
}
.gallery .gallery-photo--current {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.gallery ul.gallery-images--ul {
  position: relative;
  height: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.gallery ul.gallery-images--ul li.gallery-media-photo {
  opacity: 1;
  z-index: 2;
}
.gallery ul.gallery-images--ul li.gallery-media-photo .picture {
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
}
.gallery ul.gallery-images--ul li.gallery-media-photo .picture img.photo {
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  z-index: 0;
  border-radius: 6px;
  transform: translateY(-50%);
  box-shadow: 0 0.6rem 1rem rgba(0, 0, 0, 0.25);
}
@media (max-width: 900px) {
  .gallery ul.gallery-images--ul li.gallery-media-photo .picture img.photo {
    max-width: 95%;
  }
}
.gallery ul.gallery-images--ul li.gallery-media-photo button.photo-button {
  display: block;
  background: none;
  border-width: 0;
  cursor: pointer;
  padding: 0 !important;
  -webkit-appearance: button;
}
.gallery
  ul.gallery-images--ul
  li.gallery-media-photo
  button.photo-button:active,
.gallery
  ul.gallery-images--ul
  li.gallery-media-photo
  button.photo-button:focus {
  outline: none;
  border-width: 0;
}
.gallery .gallery-control {
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 3;
  font-size: 0.5em;
  width: 20%;
  background: none;
  border-width: 0;
  border-style: initial;
  border-color: initial;
  border-image: initial;
}
.gallery .gallery-control:focus,
.gallery .gallery-control:active {
  outline: none;
}
.gallery .gallery-control--prev {
  left: 0;
}
.gallery .gallery-control--prev svg {
  float: right;
}
.gallery .gallery-control--next {
  right: 0;
}
.gallery .gallery-control--next svg {
  float: left;
}
@media (max-width: 767px) {
  .gallery .gallery-control {
    width: 10%;
  }
}
@media (min-width: 1128px) {
  .gallery .gallery-control {
    width: 10%;
    font-size: medium;
  }
}

.gallery-modal .gallery {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  padding-bottom: 0;
}

.gallery-figcaption {
  background-image: none;
  background-color: transparent;
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  text-align: center;
  color: white;
  overflow: hidden;
  border-radius: 0;
}
.gallery-figcaption .gallery-figcaption--thumbnails {
  position: relative;
  height: 58px;
  overflow: hidden;
  transition: 0.45s ease-in-out all;
}
.gallery-figcaption .gallery-figcaption--thumbnails .caption-thumbnails {
  position: absolute;
  min-width: 100%;
  margin-left: -4px;
  margin-right: -4px;
}
.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list {
  position: absolute;
  list-style-type: none;
  margin: 0;
  padding: 0;
  left: 0;
  transition: margin 0.3s ease-out 0s;
}
.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list::after,
.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list::before {
  content: "";
  display: table;
}
.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list::after {
  clear: both;
}
.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list
  li {
  float: left;
}
.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list
  li
  + li {
  margin-left: 10;
}
.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list
  li
  button.thumbnail-button {
  cursor: pointer;
  position: relative;
  display: block;
  overflow: hidden;
  background: transparent;
  margin: 0;
  padding: 0;
}
.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list
  li
  button.thumbnail-button:focus,
.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list
  li
  button.thumbnail-button:active {
  outline: none;
}
.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list
  li
  button.thumbnail-button.active {
  cursor: default;
}
.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list
  li
  button.thumbnail-button::after,
.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list
  li
  button.thumbnail-button::before {
  border-radius: 50%;
}
.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list
  li
  button.thumbnail-button
  div.picture {
  border-radius: 4px;
  border: 4px solid transparent;
  overflow: hidden;
}
.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list
  li
  button.thumbnail-button
  div.picture
  img.thumbnail {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  z-index: 1;
  opacity: 0.3;
  filter: alpha(opacity=30);
  backface-visibility: hidden;
  object-fit: cover;
  border-radius: 4px;
  transition: 0.1s ease opacity;
}
.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list
  li
  button.thumbnail-button
  div.loading-spinner {
  position: absolute;
  z-index: 1001;
  width: 2em;
  height: 2em;
  top: 50%;
  left: 50%;
  margin: -1em 0 0 -1em;
  border-top-width: 0.15em;
  border-right-width: 0.15em;
  border-bottom-width: 0.15em;
  border-left-width: 0.15em;
  transform: translate(-50%, -50%);
}
.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list
  li
  button.thumbnail-button:hover
  div.picture
  img.thumbnail,
.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list
  li
  button.thumbnail-button.active
  div.picture
  img.thumbnail {
  opacity: 1;
  filter: alpha(opacity=100);
}
.gallery-figcaption.hide .gallery-figcaption--thumbnails {
  height: 0;
}

.mode-light .gallery-figcaption {
  color: #111;
}

.gallery-figcaption--content {
  max-width: 105vh;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.gallery-figcaption--inner {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  transform: translateY(0);
  transition: -ms-transform 0.2s ease-out 0s, -webkit-transform 0.2s ease-out 0s,
    transform 0.2s ease-out 0s;
}

.hide .gallery-figcaption--inner {
  transform: translateY(70);
}

.gallery-figcaption--info {
  display: table;
  width: 100%;
}
.gallery-figcaption--info .caption-left {
  text-align: left;
  vertical-align: middle;
  display: table-cell;
}
.gallery-figcaption--info .caption-right {
  text-align: right;
  vertical-align: middle;
  display: table-cell;
}
.gallery-figcaption--info .photo-caption,
.gallery-figcaption--info .photo-subcaption {
  margin: 0;
  line-height: 1.31;
}
.gallery-figcaption--info .photo-caption {
  font-weight: 500;
  font-size: 1rem;
}
.gallery-figcaption--info .photo-subcaption {
  color: rgba(255, 255, 255, 0.65);
  font-size: 0.851rem;
}

.mode-light .gallery-figcaption--info .photo-subcaption {
  color: rgba(0, 0, 0, 0.65);
}

.gallery-figcaption .gallery-thumbnails--toggle {
  cursor: pointer;
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: bottom;
  display: inline-block;
  color: inherit;
  font-weight: 400;
  font-size: 0.9rem;
  position: relative;
}
.gallery-figcaption .gallery-thumbnails--toggle:focus,
.gallery-figcaption .gallery-thumbnails--toggle:active {
  outline: none;
}

.gallery-figcaption .gallery-thumbnails--toggle::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.181rem;
}

.gallery-figcaption .gallery-thumbnails--toggle.hide::after {
  border-top: 6px solid #fff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.mode-light .gallery-figcaption .gallery-thumbnails--toggle.hide::after {
  border-top-color: #111;
}

.gallery-figcaption .gallery-thumbnails--toggle.open::after {
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.mode-light .gallery-figcaption .gallery-thumbnails--toggle.open::after {
  border-bottom-color: #111;
}

.gallery-modal--close {
  position: absolute;
  z-index: 10;
  right: 0;
  top: 0;
  padding: 15px;
}

.gallery-modal--close button.gallery-close {
  cursor: pointer;
  background: none transparent;
  border: 0;
  display: block;
  padding: 0.5rem;
}
.gallery-modal--close button.gallery-close:active,
.gallery-modal--close button.gallery-close:focus {
  outline: none;
}

.gallery ul.gallery-images--ul li.gallery-media-photo.loading::before {
  z-index: 999;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
}

.mode-light
  .gallery
  ul.gallery-images--ul
  li.gallery-media-photo.loading::before {
  background-color: white;
}

/* gallery animation */
.gallery__animation-appear {
  opacity: 0.01;
  transform: translateZ(0);
}

.gallery__animation-appear-active {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.gallery__image-transition--backwards-enter {
  opacity: 0.01;
  transform: translateX(-5px);
}

.gallery__image-transition--forwards-enter {
  opacity: 0.01;
  transform: translateX(5px);
}

.gallery__image-transition--backwards-enter-active,
.gallery__image-transition--forwards-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 150ms ease-out, transform 150ms ease-out;
}

.gallery__image-transition--backwards-leave,
.gallery__image-transition--forwards-leave {
  opacity: 1;
  transform: translateZ(0);
}

.gallery__image-transition--backwards-leave-active,
.gallery__image-transition--forwards-leave-active {
  opacity: 0.01;
  transition: opacity 150ms ease-out;
}

@media (max-width: 743px) {
  .gallery-figcaption--info {
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .gallery-figcaption .gallery-figcaption--info {
    display: block;
  }
  .gallery-figcaption .gallery-figcaption--info .caption-left,
  .gallery-figcaption .gallery-figcaption--info .caption-right {
    display: block;
    text-align: left;
  }
}

@media (max-width: 743px) {
  .gallery-figcaption--info {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

@media (min-width: 744px) {
  .gallery .gallery-photo {
    padding-bottom: 67%;
  }
  .gallery-figcaption--info {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
